<template>
    <b-row>
      <b-col md="3">
        <side-bar-menu title="Résultats">
        <ul>
          <li>
            Championnat de France Acrobatique
            <ul>
              <li>2022</li>
              <li>2021</li>
              <li>2020</li>
              <li>Synthèse</li>
            </ul>
          </li>
          <li>
            Championnat de France Indoor
            <ul>
              <li v-for="year in CdfOutYears" :key="year.key">
                <b-link>{{ year.value }}</b-link>
              </li>
              <li>Synthèse</li>
            </ul>
          </li>
        </ul>
        </side-bar-menu>
      </b-col>
      <b-col md="8">
        <router-view></router-view>
      </b-col>
    </b-row>

</template>

<script>
import SideBarMenu from '../../components/Panels/SideBarMenu.vue';

export default{
  components: { SideBarMenu, },
  mounted(){
    this.$hideLoader();
  }
}
</script>

